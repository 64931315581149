<template>
  <div>
    <b-modal
      id="historicoInativacaoList"
      ref="historicoInativacaoList"
      button-size="sm"
      no-close-on-backdrop
      no-stacking
      ok-olny
      ok-title="Ok"
      size="lg"
      cancel-title=""
      :cancel-disabled="true"
      cancel-variant="false"
    >
      <div slot="modal-title">
        <h5>{{ 'Histórico de inativação - ' + empresa.razaoSocial }}</h5>
        <fieldset class="font-weight-bold text-muted">
          Histórico de inativação e reativação da empresa
        </fieldset>
      </div>
      <visualizacao
        :empresa="empresa.historicoInativacoes"
      />
    </b-modal>
  </div>
</template>

<style lang="scss">
.modal:nth-of-type(even) {
  z-index: 1052 !important;
}
.modal-backdrop.show:nth-of-type(even) {
  z-index: 1051 !important;
}
</style>

<script>
export default {
  name: 'HistoricoInativacaoView',
  components: {
    Visualizacao: () => import('./shared/components/visualizacao/Visualizacao.vue'),
  },
  props: {
    empresa: {
      type: Object,
      required: true,
    },
  },
  methods: {
    show() {
      this.$refs.historicoInativacaoList.show()
    },
  },
}

</script>
